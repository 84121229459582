/*Adding custom checkbox icons*/
.pro_checkbox label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    padding-top: 5px;
}
.pro_checkbox label:before, label:after {
    font-family: FontAwesome;
    font-size: 21px;
    /*absolutely positioned*/
    position: absolute; top: 0; left: 0;
}
.pro_checkbox label:before {
    content: '\f096'; /*unchecked*/
}
.pro_checkbox label:after {
    content: '\f046'; /*checked*/
    /*checked icon will be hidden by default by using 0 max-width and overflow hidden*/
    max-width: 0;
    overflow: hidden;
    opacity: 0.5;
    /*CSS3 transitions for animated effect*/
    transition: all 0.35s;
}

/*hiding the original checkboxes*/
.pro_checkbox input[type="checkbox"] {
    display: none;
}
/*when the user checks the checkbox the checked icon will animate in*/
.pro_checkbox input[type="checkbox"]:checked + label:after {
    max-width: 25px; /*an arbitratry number more than the icon's width*/
    opacity: 1; /*for fade in effectthird
/*adding some colors for fun*/
}
.pro_checkbox .first_color+label:before, .pro_checkbox .first_color+label:after {color: hsl(0, 45%, 40%);}
.pro_checkbox .second_color+label:before, .pro_checkbox .second_color+label:after {color: hsl(60, 45%, 40%);}
.pro_checkbox .third_color+label:before, .pro_checkbox  .third_color+label:after {color: hsl(120, 45%, 40%);}
.pro_checkbox .fourth_color+label:before, .pro_checkbox  .fourth_color+label:after {color: hsl(180, 45%, 40%);}
.pro_checkbox .fifth_color+label:before, .pro_checkbox  .fifth_color+label:after {color: hsl(240, 45%, 40%);}
.pro_checkbox .sixth_color+label:before, .pro_checkbox  .sixth_color+label:after {color: hsl(300, 45%, 40%);}